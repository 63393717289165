import React from "react"
import { Link } from "gatsby"

import { PageSectionWrap, PageTitle, PageForm, List, Story } from "../objects"
import FadeUp from "../fade-in-up"
import Form from "../form"

export default ({ title, summary, process }) => {
  //form handling
  const formKeys = {
    staffName: "",
    staffEmail: "",
    staffNumber: "",
    staffPosition: "",
  }

  return (
    <PageSectionWrap>
      <PageTitle>
        <FadeUp>{title}</FadeUp>
      </PageTitle>

      <Story>
        <FadeUp>
          <p>{summary}</p>
        </FadeUp>
        <FadeUp>
          <List>
            {process.map((item, index) => (
              <li key={index}>{item.process_list_item[0].text}</li>
            ))}
          </List>
        </FadeUp>
      </Story>

      <PageForm
        name="apply"
        method="POST"
        netlify-honeypot="bot-field"
        data-netlify="true"
      >
        <input name="bot-field" type="hidden" />
        <FadeUp>
          <Form mode="dark" formName="apply" formKeys={formKeys}>
            <section className="form-header">
              <h3>Start your application</h3>
              <p>
                A Switch40 representative will contact you after submitting the
                form below.
              </p>
            </section>

            <div className="form-row">
              <label>
                <span>Your Name</span>
                <input
                  className="form-input"
                  type="text"
                  required
                  name="staffName"
                />
              </label>
            </div>

            <div className="form-row">
              <label>
                <span>Email</span>
                <input
                  className="form-input"
                  type="email"
                  required
                  name="staffEmail"
                />
              </label>
            </div>

            <div className="form-row">
              <label>
                <span>Mobile Number</span>
                <input
                  className="form-input"
                  type="text"
                  required
                  name="staffNumber"
                />
              </label>
            </div>

            <div className="form-row">
              <label>
                <span>What positions are you interested in?</span>
                <select name="staffPosition" required className="form-input">
                  <option value=""></option>
                  <option value="Kitchen Assistant">Kitchen Assistant</option>
                  <option value="Cleaner">Cleaner</option>
                  <option value="Cook">Cook</option>
                  <option value="Nurse">Nurse</option>
                  <option value="Social Worker">Social Worker</option>
                  <option value="Care Assistant">Care Assistant</option>
                  <option value="Other">Other</option>
                </select>
              </label>
            </div>

            <div className="form-row">
              <button>
                <span>Submit</span>
                <figure></figure>
              </button>
            </div>

            <p className="legal">
              *By clicking 'Submit', you agree with the handling of your data in
              accordance with the Switch40{" "}
              <Link to="/privacy-policy">Privacy Policy</Link>.
            </p>
          </Form>
        </FadeUp>
      </PageForm>
    </PageSectionWrap>
  )
}
