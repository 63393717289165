import React from "react"
import { graphql } from "gatsby"

import Meta from "../utilities/seo"

import Page from "../components/page"
import { PageBkg } from "../components/objects"
import Apply from "../components/find-work/apply"

export default ({ data }) => {
  let node = data.prismic.allFind_works.edges[0].node

  return (
    <>
      <Meta title="Find Work - Switch40" />
      <Page photo={node.page_image.url}>
        <PageBkg>
          <Apply
            title={node.title[0].text}
            summary={node.summary[0].text}
            process={node.process_list}
          />
        </PageBkg>
      </Page>
    </>
  )
}

export const query = graphql`
  {
    prismic {
      allFind_works {
        edges {
          node {
            page_image
            summary
            title
            process_list {
              process_list_item
            }
          }
        }
      }
    }
  }
`
